import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const ProductQuality = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="ProductQuality"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">Brings wide range of consulting experience and service development expertise encompassing vertical needs, automation embedment and operating levers to transform QE. We help establish an intelligent quality engineering function with predictable outcomes focused on ROI, customer experience, and business alignment, to make sure your quality engineering function meets with business objectives.</p>
                        <h6>Our test advisory and consulting capability include:-</h6>
                        <ul className="circle-list">
                            <li className="mt-2">Business Applications Quality Consultancy</li>
                            <li className="mt-2">TALM Implementation</li>
                            <li className="mt-2">Test Management and Consulting</li>
                            <li className="mt-2">Strategic Quality Engineering Consulting</li>
                            <li className="mt-2">Test Process Reviews</li>
                            <li className="mt-2">Test Process Optimisation</li>
                            <li className="mt-2">Federated Test Centre of Excellence</li>
                            <li className="mt-2">Quality Engineering Transformation Consultancy</li>
                        </ul>
                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query ProductQualityPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

ProductQuality.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default ProductQuality;
